<template>
  <div class="ln-tip">
    <div class="ln-tip__cover">
      <div class="ln-tip__circle">
        <slot name="icono"></slot>
      </div>
    </div>
    <div class="ln-tip__main">
      <h4 class="ln-tip__title">
        <div class="ln-tip__ico">
          <slot name="icono"></slot>
        </div>
        <slot name="titulo"></slot>
      </h4>
      <div class="ln-tip__body">
        <slot name="descripcion"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingComoListItem",
};
</script>
