<template>
  <section class="ln-sorteo">
    <div class="bb-container">
      <div class="ln-sorteo__container">
        <img
          class="ln-sorteo__corner ln-sorteo__corner--top-left"
          src="../../../assets/img/landings/ico/ico-stars-top-left.svg"
          alt=""
        />
        <img
          class="ln-sorteo__corner ln-sorteo__corner--top-right ln-sorteo__corner--m"
          src="../../../assets/img/landings/ico/ico-stars-top-right.svg"
          alt=""
        />
        <img
          class="ln-sorteo__corner ln-sorteo__corner--bottom-left ln-sorteo__corner--m"
          src="../../../assets/img/landings/ico/ico-stars-bottom-left.svg"
          alt=""
        />
        <img
          class="ln-sorteo__corner ln-sorteo__corner--bottom-right ln-sorteo__corner--m"
          src="../../../assets/img/landings/ico/ico-stars-bottom-right.svg"
          alt=""
        />

        <div class="ln-sorteo__left">
          <div class="ln-sorteo__title">
            <slot name="titulo"></slot>
          </div>
          <div class="ln-sorteo__cover ln-sorteo__cover--m">
            <slot name="imagen"></slot>
          </div>
          <div class="ln-sorteo__description">
            <slot name="descripcion"></slot>
          </div>
        </div>
        <div class="ln-sorteo__cover ln-sorteo__cover--d">
          <slot name="imagen"></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LandingSorteoSection",
};
</script>
