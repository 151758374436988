<template>
  <div class="ln-hero-af">
    <img
      class="ln-hero-af__bg ln-hero-af__bg--1"
      src="../../../assets/img/landings/ln-hero-af-bodi.png"
      alt=""
    />
    <img
      class="ln-hero-af__bg ln-hero-af__bg--2"
      src="../../../assets/img/landings/ln-hero-af-carro.png"
      alt=""
    />
    <img
      class="ln-hero-af__bg ln-hero-af__bg--3"
      src="../../../assets/img/landings/ln-hero-af-biberon.png"
      alt=""
    />
    <img
      class="ln-hero-af__bg ln-hero-af__bg--4"
      src="../../../assets/img/landings/ln-hero-af-cuna.png"
      alt=""
    />
    <div class="bb-container bb-container--lista">
      <portal-target name="alerts_landingafiliado"></portal-target>
      <alert v-if="showAlert" @retry="dialogAction" />

      <div class="ln-hero-af__container">
        <div class="ln-hero-af__toast">
          <div class="ln-toast">
            <span class="ln-toast__content">
              ¡<span>{{ referralUserName }}</span> te recomienda HelloBB para hacer tu lista de nacimiento!
            </span>
            <span class="ln-toast__actions">
              <router-link :to="{ name: 'guest', params: { id: referralUserListId } }" class="link link--bold link--underline" target="_blank">
                Echa un vistazo a su lista
              </router-link>
            </span>
          </div>
        </div>
        <div class="ln-hero-af__title">
          10€ de parte de {{ referralUserName }} para empezar bien la lista de tu bebé
        </div>
        <div class="ln-hero-af__disclaimer">
          <span class="link link--bold link--underline" @click.stop="scrollToConditions()"
            >Ver condiciones</span
          >

          <modal-dialog
            :show="showDialog"
            @close="showDialog = false"
            target-class="modal__card--indigo-4"
          >
            <div class="content-modal content-modal--left">
              <div class="content-modal__title">Condiciones</div>

              <div class="content-modal__form"></div>
              <div class="content-modal__body">
                <div class="post-entry">
                  <p>
                    Para entrar en la promoción, debes hacer tu lista con HelloBB (no solo
                    empezarla 😉). Esto significa:
                  </p>
                  <ol>
                    <li>
                      <strong>
                        Poner al menos 5 cosas que quieres en tu lista (pueden ser de
                        cualquier tienda).
                      </strong>
                    </li>
                    <li>
                      <strong>
                        Comprar algo en la tienda de HelloBB por valor de 30€ o más (¡puedes hacer la compra tú o la gente con la que compartas la lista).
                      </strong>
                    </li>
                  </ol>
                </div>
              </div>
              <div class="content-modal__actions content-modal__actions--center">
                <bbButton
                  class="button--accent"
                  label="¡Empezar!"
                  @click="dialogAction"
                  :loading="loading"
                >
                </bbButton>

                <span
                  class="link link--medium link--underline"
                  @click="showDialog = false"
                >
                  No me interesa
                </span>
              </div>
            </div>
          </modal-dialog>
        </div>
        <div class="ln-hero-af__form scrollto">
          <section class="ln-hero-form">
            <h2 class="ln-hero-form__subtitle text-center">
              Empieza ya tu lista de nacimiento
            </h2>
            <div class="ln-hero-form__form">
              <InputText
                label="Tu Nombre"
                v-model="username"
                :errorLabel="usernameError"
                placeholder="Escribe tu nombre"
                @focus="usernameError = ''"
              />
              <InputText
                label="Tu Email"
                v-model="email"
                :errorLabel="emailError"
                placeholder="Escribe tu email"
                @focus="emailError = ''"
              />
              <InputText
                label="Tu Contraseña"
                v-model="password"
                type="password"
                :errorLabel="passwordError"
                placeholder="Elige una contraseña"
                @focus="passwordError = ''"
              />
              <div class="ln-hero-form__aceptance">
                <label for="aceptance" class="bb-form-checkbox">
                  <input
                    type="checkbox"
                    id="aceptance"
                    v-model="privacity"
                    required
                    @change="privacityError = ''"
                  />
                  <span>
                    Acepto los
                    <a
                      href="/legal"
                      target="_blank"
                      class="link link--bold link--underline"
                    >
                      términos y condiciones
                    </a>
                  </span>
                </label>
              </div>
              <div v-if="privacityError" class="bb-form-error">
                {{ privacityError }}
              </div>
            </div>
            <div class="ln-hero-form__actions">
              <bbButton
                class="button--accent button--block"
                :label="buttonText"
                @click="openConditionsModal(false, signUp)"
              >
              </bbButton>
            </div>
            <login-social
              :show="true"
              :showFacebook="false"
              :showLabel="true"
              class="login-box__social"
              color="white"
              size="large"
              :origin="origin"
              :referralUserId="referralUserId"
              :beforeCallback="openConditionsModalFromSocialButton"
            ></login-social>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import LoginSocial from "../../../components/ui/login/LoginSocial.vue";
import InputText from "../../../components/forms/InputText.vue";
import bbButton from "../../../components/buttons/bbButton.vue";
import axios from "axios";
import ModalDialog from "../../../components/ui/ModalDialog.vue";

const crypto = require("crypto");
import loginMixin from "../../mixins/login.js";

import LandingAfiliadoAlert from "./LandingAfiliadoAlert";

export default {
  name: "LandingHeroAfiliado",
  mixins: [loginMixin],
  components: {
    LoginSocial,
    InputText,
    bbButton,
    ModalDialog,
    alert: LandingAfiliadoAlert,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: "Empieza tu lista ahora",
    },
    subtitle: {
      type: String,
      required: false,
    },
    origin: {
      type: String,
      required: true,
    },
    showLoginSocial: {
      type: Boolean,
      default: false,
    },
    classNameCover: String,
    referralUserId: {
      type: String,
      required: true,
    },
    scrollToConditions: {
      type: Function,
      required: true,
    },
    referralUserName: {
      type: String,
      required: true,
    },
    referralUserListId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      showAlert: "showAlert",
    }),
    isFormValid() {
      return (
        this.username &&
        this.email &&
        this.password &&
        this.privacity &&
        this.validateEmail()
      );
    },
  },
  data() {
    return {
      username: "",
      email: "",
      password: "",
      privacity: false,
      loading: false,
      emailError: "",
      usernameError: "",
      passwordError: "",
      privacityError: "",
      showDialog: false,
      dialogAction: null,
    };
  },
  methods: {
    openConditionsModal(isSocialLogin = false, dialogAction = null) {
      this.resetErrors();

      if (!isSocialLogin) {
        if (!this.username || !this.email || !this.password || !this.privacity) {
          if (!this.username) this.usernameError = "El nombre es obligatorio";
          if (!this.email) this.emailError = "El email es obligatorio";
          if (!this.password) this.passwordError = "La contraseña es obligatoria";
          if (!this.privacity)
            this.privacityError = "Debe aceptar los términos y condiciones";
          this.loading = false;
        }

        if (!this.validateEmail()) {
          this.emailError = "El email no es válido";
          this.loading = false;
        }

        if (!this.isFormValid) {
          return;
        }

        this.dialogAction = null;
      }

      if (dialogAction !== null) {
        if (typeof dialogAction !== "function") {
          throw new Error("dialogAction must be a function");
        }

        this.dialogAction = dialogAction;
      }

      this.showDialog = true;
    },
    openConditionsModalFromSocialButton(callback) {
      this.openConditionsModal(true, callback);
    },
    async signUp() {
      this.loading = true;
      try {
        const baseURL = process.env.API;
        try {
          const response = await axios.post(`${baseURL}/user/signup`, {
            name: this.username,
            email: this.email,
            password: this.password,
            SignupOrigin: this.origin,
            referralUserId: parseInt(this.referralUserId),
          });

          this.setModalShownCookie();
        } catch (error) {
          if (parseInt(error.response?.status) === 409) {
            this.loading = false;

            this.error = "Este email ya ha sido registrado. ¿Quieres iniciar sesión?";
            await this.$sleep(this.goToLogin, 2000);

            return;
          } else {
            this.showDialog = false;

            this.$alert.error("Algo ha ido mal. No hemos podido guardar los cambios.");
          }

          return;
        }

        window.mixpanel.people.set("Signup Origin", this.origin);
        this.$root.trackConversion({
          email: this.email,
        })

        await this.login("../onboarding-1");
      } finally {
        this.loading = false;
      }
    },
    validateEmail() {
      const email = this.email.toLowerCase();
      const parts = email.split("@");
      if (parts.length !== 2) {
        return false;
      }

      const [left, right] = parts;

      return (
        left.match(/^\w+([.-_+]\w+)*$/) &&
        right.match(/^\w+([.-]\w+)*\.\w{2,10}$/)
      );
    },
    setModalShownCookie() {
      const days = 30;
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + days);

      const currentTimestamp = new Date().getTime().toString();
      const randomValue = Math.random().toString();
      const uniqueHash = crypto
        .createHash("sha256")
        .update(currentTimestamp + randomValue)
        .digest("hex");

      document.cookie = `modalShown=${uniqueHash}; expires=${expirationDate.toUTCString()}; path=/`;

      // Acceder a la cookie y mostrar su valor en la consola
      const cookieValue = document.cookie.replace(
        /(?:(?:^|.*;\s*)modalShown\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
    },
    async goToLogin() {
      await this.$router.push({ name: "login" });
    },
    resetErrors() {
      this.usernameError = "";
      this.emailError = "";
      this.passwordError = "";
      this.privacityError = "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
