<template>
  <section class="ln-sorteo-bases">
    <div class="ln-sorteo-bases__titulo">
      <slot name="titulo"></slot>
    </div>
    <div class="ln-sorteo-bases__descripcion">
      <div class="post-entry">
        <slot name="descripcion"></slot>
      </div>
    </div>

    <div class="back-to-top" v-if="showToTop">
      <bbButton
        label="Empezar ahora"
        @click="scrollToTop()"
        class="button--accent button--wide-4"
      />
    </div>
  </section>
</template>

<script>
import bbButton from "../../../components/buttons/bbButton.vue";

export default {
  name: "LandingSorteoBases",
  components: {
    bbButton,
  },
  props: {
    showToTop: {
      value: Boolean,
      default: false,
    },
  },
  methods: {
    scrollToTop() {
      //scroll to element with class = scrollto
      const el = document.querySelector(".scrollto");
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
