<template>
  <div class="ln-tips">
    <div class="bb-container bb-container--lista">
      <div class="ln-head">
        <div class="ln-head__titulo">{{ title }}</div>
        <div class="ln-head__subtitulo">{{ subtitle }}</div>
      </div>

      <div class="ln-tips__items" v-if="useCarousel">
        <VueSlickCarousel id="vscomolist" v-bind="comoSlider">
          <slot></slot>
        </VueSlickCarousel>
      </div>

      <div class="ln-tips__items" v-else>
        <slot></slot>
      </div>

      <div class="back-to-top">
        <bbButton
          label="Empezar ahora"
          @click="scrollToTop()"
          class="button--accent button--wide-4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import bbButton from "../../../components/buttons/bbButton.vue";

export default {
  name: "LandingComoList",
  components: {
    bbButton,
    VueSlickCarousel,
  },
  props: {
    title: String,
    subtitle: String,
    useCarousel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      comoSlider: {
        slidesToShow: 4,
        arrows: false,
        responsive: [
          {
            breakpoint: 9999,
            settings: "unslick",
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              draggable: true,
              dots: true,
            },
          },
        ],
      },
    };
  },

  methods: {
    scrollToTop() {
      const el = document.querySelector(".scrollto");
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
